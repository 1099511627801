import { React, useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'primereact/button'
import { Card } from 'primereact/card'
import { Toast } from 'primereact/toast'
import { InputText } from 'primereact/inputtext'
import { Password } from 'primereact/password'
import { useNavigate } from 'react-router-dom'
import { loginActions } from 'actions'
import assets from 'assets'
import './LoginForm.css'

const tokenSelector = state => state.loginUserReducer.user?.token
const expirySelector = state => state.loginUserReducer.user?.expiry
const errorSelector = state => state.loginUserReducer.error
const userSelector = state => state.loginUserReducer?.user

const LoginForm = () => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const toast = useRef(null)

  const currentToken = useSelector(tokenSelector)
  const expiry = useSelector(expirySelector)
  const error = useSelector(errorSelector)
  const user = useSelector(userSelector)

  useEffect(() => {
    if (error) {
      let errorDetail = ''
      if ('response' in error) {
        errorDetail = error.response?.data?.message
      } else if ('code' in error) {
        errorDetail = error.code
      }
      toast.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERR,
        detail: assets.SERVER_MESSAGES.ERRORS[errorDetail],
        life: 3000
      })
      dispatch({ type: 'RESET_LOGIN' })
    }
  }, [error])

  const dispatch = useDispatch()

  const navigate = useNavigate()

  useEffect(() => {
    if (currentToken) {
      navigate('/')
    }
  }, [currentToken])

  const handleLogin = () => {
    if (username && password) {
      dispatch(loginActions.login(username, password))
    } else {
      toast.current.show({
        severity: 'error',
        summary: assets.SERVER_MESSAGES.ERRORS.ERR_NO_CREDENTIALS,
        life: 3000
      })
    }
  }
  return (
    <div className="login-container">
      <Card className='login-form-card '>
        <Toast icon='pi pi-exclamation-triangle' ref={toast} />
        <div className='login-form'>
          <div className=''>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon'>
                <i className='pi pi-user' />
              </span>
              <InputText
                name='username'
                onChange={evt => setUsername(evt.target.value)}
                value={username}
                placeholder='Username'
              />
            </div>
          </div>
          <div className=''>
            <div className='p-inputgroup'>
              <span className='p-inputgroup-addon'>
                <i className='pi pi-lock' />
              </span>
              <Password
                name='password'
                feedback={false}
                onChange={evt => setPassword(evt.target.value)}
                value={password}
                placeholder='Password'
              />
            </div>
          </div>
          <div className='p-offset-1 p-lg-offset-4 p-md-offset-2 p-sm-offset-1 p-col-10 p-lg-4 p-md-8 p-sm-10'>
            <Button
              label='Login'
              className='p-button-raised'
              onClick={handleLogin}
            />
          </div>
        </div>
      </Card>
      <div className="support-message">
      <p>
         To register, please send an email to <strong><a href="mailto:contact@nextlab.tech">contact@nextlab.tech</a></strong>.
      </p>
</div>
    </div>
  )
}

export default LoginForm
